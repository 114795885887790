<template>
  <section class="breadcrumbs">
    <div class="container">
      <b-breadcrumb :items="items" class="pb-0"></b-breadcrumb>
    </div>
  </section>
</template>

<script>
export default {
  name: "Breadcrumbs",
  computed: {
    items() {
      const items = [];
      const bcrumbs = this.$store.getters["breadcrumbs/getBreadcrumbsRoutes"];
      let item = { text: "Home", to: "/" };
      items.push(item);
      bcrumbs.forEach((bcrumb) => {
        item = { text: bcrumb.name, to: bcrumb.route_link };
        items.push(item);
      });
      const curr = this.$store.getters["breadcrumbs/getBreadcrumbsCurrent"];
      item = { text: curr, active: true };
      items.push(item);
      return items;
    },
  },
};
</script>


