<template>
  <AppLayout>
    <Breadcrumbs />
    <section class="static pt-7">
      <b-container>
        <b-row>
          <!-- <b-col lg="3" class="d-none d-lg-block">
            <div class="sidebar">
              <span class="sidebar-heading">Links</span>
              <cmsBlockSimple identifier="side-menu-cms" />
            </div>
          </b-col> -->
          <b-col lg="12">
            <h1>{{ cmsPageContent.title }}</h1>
            <VRuntimeTemplate
              v-if="cleanContent"
              :template="`<div class='cms-content'>${cleanContent}</div>`"
            ></VRuntimeTemplate>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </AppLayout>
</template>

<script>

import AppLayout from "@/esf_kerkrade_vitanatura/core/components/AppLayout.vue";
// import cmsBlockSimple from "@/base/core/components/core/BlockSimple";

import Breadcrumbs from "@/esf_kerkrade_vitanatura/core/components/Breadcrumbs";
import VRuntimeTemplate from "v-runtime-template";
export default {
  name: "StaticPage",
  components: {
    AppLayout,
    Breadcrumbs,
    VRuntimeTemplate,
  },
  computed: {
    cmsPageContent() {
      return this.$store.state.cmsPage.current;
    },
    cleanContent() {
      return this.cleanHtml(this.cmsPageContent.content);
    },
  },
  methods: {
    cleanHtml(htmlContent) {
      if (!htmlContent) return "";
      return htmlContent.replace(/<p>(&nbsp;|\s)*<\/p>/g, "").trim();
    },
  },
  mounted() {
    const bcrumb = { current: this.cmsPageContent.title, routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
  },
  metaInfo() {
    return {
      meta: [
        { name: "title", content: this.cmsPageContent.meta_title },
        { name: "keywords", content: this.cmsPageContent.meta_keywords },
        { name: "description", content: this.cmsPageContent.meta_description },
      ],
      title: this.cmsPageContent.title,
    };
  },

  data: () => ({}),
};
</script>

